import React, {useContext, useEffect} from 'react';
import { fetchGet, getWispUrl, wisp_urls } from './util/fetch.js';
import {fileType} from './util/document.js';
import { createSiteMap, deleteSiteMap, getFirstPageDoc, getSiteFromUrl } from './util/site.js';
import {loadUserSites} from './util/user.js';
import Decision from './components/Decision';
import { NextLink } from './components/NextLink.js';
import { PrevLink } from './components/PrevLink.js';
import {WispNav} from './components/WispNav';
import {WispContext} from './WispProvider';
import {BookSettings} from './BookSettings';
import {PageView} from './PageView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Document, Page, pdfjs } from 'react-pdf';
import PortfolioTable from './PortfolioTable.js';

// const $ = window.$;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const SiteView = (props) => {

    var wispData = useContext(WispContext);
	const [busyMapId, setBusyMapId] = React.useState(null);
	const [curPageId, setCurPageId] = React.useState(0);
	const [curView, setCurView] = React.useState(null);
	const [docMapsLoaded, setDocMapsLoaded] = React.useState(false);
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [loadedPages, setLoadedPages] = React.useState(false);
	const [navOpen, setNavOpen] = React.useState(!wispData.mobileView);
	const [refreshingDocMaps, setRefreshingDocMaps] = React.useState(false);
	const [showSubscribeDlg, setShowSubscribeDlg] = React.useState(false);

	// pdf stuff
	const [numPages, setNumPages] = React.useState(null);
	// const [pageNumber, setPageNumber] = React.useState(1);

    useEffect(() => {

		if (props.site) {
			if (props.site.access === 'subscription') {
				if (props.site.user_access) {
					if (props.site.user_access.role != 'admin' && props.site.user_access.role != 'author') {
						var today = new Date();
						var time_now = today.getTime();
						var time = props.site.user_access.end_date;
						var ttime = new Date(time);
						if (ttime < time_now) {
							console.log('siteview 1');
							wispData.showSubscribePopup(true);
							wispData.setSearchBookURL(props.site.url);
						}
					}
				} else {
					console.log('siteview 2');
					wispData.showSubscribePopup(true);
					wispData.setSearchBookURL(props.site.url);
				}
			}
			if (!loadedPages) {
				setLoadedPages(true);
				var url = getWispUrl(wisp_urls.site_pages_get, props.site.id);
				fetchGet(url, getPagesResult, wispData);
			}
		}

		if (curPageId !== 0) {
			if (!refreshingDocMaps) {
				setRefreshingDocMaps(true);
				url = getWispUrl(wisp_urls.site_docMaps_get, props.site.id);
				fetchGet(url, getDocMapsResult, wispData, true);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [curPageId]);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

    function getPagesResult(success, data) {
        if (success && props && props.site) {
            // put the site pages into the dictionary of site pages
            var curValue = wispData.sitePages;
            if (typeof curValue !== 'undefined') {
                curValue[props.site.id] = data;
            } else {
                // var id = props.site.id;
                curValue = { id: data };
            }
            wispData.setSitePages(curValue);

            // this needs work, but for now if there's only one page then load the page docs
            if (data.length >= 1) {
                setCurPageId(data[0].id);	//hooks suck - if the fetchGet below doesn't call the backend then 
            }
        }
	}

	function getDocMapsResult(success, data) {
		if (success) {
			wispData.setSiteDocMaps(data);
			setDocMapsLoaded(true);
			// figure out the view - to get here app.js already ensured that we have access rights
			var map;
			if (props.docId) {
				map = getMapFromDocId(data, props.docId);
				selPanDoc(map);
			} else if (props.site && props.site.user_access && (props.site.user_access.role === 'admin' || props.site.user_access.role === 'author')) {	// iis author or admin
				setView({ type: 'settings', value: {} });
			//} else if (props.site && props.site.user_access && (props.site.user_access.role !== 'admin' && props.site.user_access.role !== 'author')) {
			//	map = getFirstPageDoc(curPageId, data);	// can't count on wispData.siteDocMaps being set yet
			//	selPanDoc(map);
			//} else if (props.site && (props.site.access == 'public')) {
			} else {
				map = getFirstPageDoc(curPageId, data);	// can't count on wispData.siteDocMaps being set yet
				selPanDoc(map);
			}
			setForceUpdate(forceUpdate + 1);

		}
	}

	function getMapFromDocId(maps, docId) {
		var themap;
		maps.forEach((map) => {
			if (map.doc_id === parseInt(docId)) {
				themap = map;
			}
		});
		return themap;
	}

/*    function getDocsResult(success, data, pageId) {
        if (success) {
            // put the page docs into the dictionary of page docs
            // this needs work
            var curValue = wispData.pageDocMaps;
            if (typeof curValue != 'undefined') {
                curValue[pageId] = data;
            } else {
                curValue = { pageId: data };
            }
            wispData.setPageDocMaps(curValue);

			// figure out the view - if member should go to first doc in list
			if (props.docId) {
				var map = getMapFromDocId(data, props.docId);
				selPanDoc(map);
			} else if (props.site && (props.site.user_access.role !== 'admin' && props.site.user_access.role !== 'author')) {
				var map = wispData.pageDocMaps[pageId][0];
				selPanDoc(map);
			}
			setForceUpdate(forceUpdate + 1);
        }
	} */

	function selPanDoc(map) {
		if (map) {
			if (!wispData.docContent[map.doc_id] && fileType(map.filename) !== 'pdf') {
				setBusyMapId(map.id);
				var url = getWispUrl(wisp_urls.document_get, map.doc_id);
				if (wispData.mobileView) {
					url += "?view=mobile";
				} else {
					url += "?view=desktop";
				}
				url += "&format=html";
				// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
				// style can be 'classic' or 'blog'
				fetchGet(url, getDocResults2, wispData, true, map, {style: 'classic' }, 'html');
			} else {
				setView({ type: 'document', value: map });
				setTimeout(function () { window.scrollTo(0, 0); }, 100);		
			}
			if (wispData.mobileView) {
				setNavOpen(false);	
			}
		}
	}

	function getDocResults2(success, data, map) {
		if (success) {
			var content = wispData.docContent;
			content[map.doc_id] = data;
			wispData.setDocContent(content);
			setView({ type: 'document', value: map });
			setBusyMapId(null);
			setTimeout(function () { window.scrollTo(0, 0); }, 100);		
		}
	}

    function setView(view) {
        setCurView(view);
    }

	function wisp_popup_toggle() {
		setNavOpen(!navOpen);
	}

	function showSubscribe() {
		setShowSubscribeDlg(true);
	}

	function doSubscribe() {
		if (wispData.publicState.wispUser) {
			createSiteMap(wispData, 'member', props.site.id.toString(), 'active', wispData.publicState.wispUser.id, onSiteMapCreated);
		} else {
			wispData.setSubscribeSite(props.site);
			wispData.setCreateAccNote("Please sign-in or create a new WispBook account. A free account is required to subscribe to " + props.site.name + " to get notified when content is updated.");
			wispData.setShowCreateAccount(true);
		}
		subscribeCancel();
	}

	function subscribeCancel() {
		setShowSubscribeDlg(false);
	}

	function onSiteMapCreated() {
        // reload sitemaps
        loadUserSites(wispData, wispData.publicState.wispUser.id, wispData.publicState.wispUser.id, wispData.token, getSitesResult, true, null);
	}

	function doUnSubscribe(site) {
		// gotta be signed in for this to get called
		deleteSiteMap(wispData, site.user_access.id, doneUnfollow);
	}

	function doneUnfollow() {
		loadUserSites(wispData, wispData.publicState.wispUser.id, wispData.publicState.wispUser.id, wispData.token, getSitesResult, true, null);
	}

	function getSitesResult(success, data, userId) {
		if (success) {
			wispData.setUserSitesAuth(data);
            wispData.setUserSitesLoaded(true);
        }
    }


	var guardian;
	if (wispData.mobileView) {
		var guardianIcon = faArrowRight;
		var gclass = 'closed';
		if (navOpen) {
			guardianIcon = faArrowLeft;
			gclass = 'open';
		}
		guardian = (
			<div className={gclass + ' wisp_guardian'} onClick={wisp_popup_toggle}>
				<div className='wisp_m_icon'>
					<FontAwesomeIcon icon={guardianIcon} />
				</div>
			</div>
		);
	}

	// do we have a sitemap to a public site?
	var showSubscribeStr = '';
	var subscribeFN;
	if (props.site && props.site.access == 'public') {
		if (getSiteFromUrl(props.site.url, wispData.userSitesAuth) == null) {
			showSubscribeStr = 'sub';
			subscribeFN = showSubscribe;
		} else if (props.site.user_access && props.site.user_access.role === 'member'){
			showSubscribeStr = 'unsub';
			subscribeFN = doUnSubscribe;
		}
	}

    let subscribeDialog;
    if (showSubscribeDlg) {
        var mes = "Do you want to subscribe to " + props.site.name + " and get notified when content changes? You can unsubscribe at any time.";
        subscribeDialog = (
            <Decision
                mes={mes}
                c1="Subscribe"
                c2="Cancel"
                fn1={doSubscribe}
                fn2={subscribeCancel}
                p1=""
                p2=""
                y="40"
				closeFN={subscribeCancel}
            />
        );
    }


	var siteDocMaps = wispData.siteDocMaps;
	var docView;
	var nav;

	var postLinks;

    if (curView && !wispData.publicState.showSubscribe) {
		if (curView.type === 'settings' && props.site && props.site.user_access && (props.site.user_access.role === 'admin' || props.site.user_access.role === 'author')) {
			docView = (
				<PortfolioTable
					siteId = {props.site.id}
					userId = {wispData.publicState.wispUserId}
					token = {wispData.token}
				/>
			);
		} else if (docMapsLoaded && siteDocMaps.length === 0) {
			docView = (
				<div className='noContentDiv'>
					<div>No content is currently available for <i>{props.site.name}</i>.</div>
					<div style={{ marginTop: '20px' }}> Please check back later or contact the book author.</div>
				</div>
			);
		} else if (curView.type === 'document'){
			postLinks = (
				<div className='postLinkContainer wispBookLinks'>
					<PrevLink siteDocMaps={siteDocMaps} curPageId={curPageId} value={curView.value} selPanDocFN={selPanDoc} useName />
					<NextLink siteDocMaps={siteDocMaps} curPageId={curPageId} value={curView.value} selPanDocFN={selPanDoc} useName />
				</div>
			 );
			if (curView.value.url != null && curView.value.url != '') {
				var cls = '';
				if (navOpen) {
					cls = 'float';
				}
				var url = curView.value.url;
				var i = url.lastIndexOf('/');
				if (i > 0) {
					url = 'https://www.youtube.com/embed' + url.substring(i);
				}
				 docView = (
					<div id='wispPageContId' className={'wispPageContainer wispBlog ' + cls} style={{marginTop: '80px', width: '100%', height: '100%'}}>
						<iframe width='100%' height = '100%' src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						{postLinks}
					</div>
				)
		   
			} else if (fileType(curView.value.filename) !== 'pdf') {
				docView = (
					<PageView value={curView.value} navOpen={navOpen} links={postLinks} />
				)
			} else {
				var url = getWispUrl(wisp_urls.document_get, curView.value.doc_id) + '?format=native&view=mobile';
//				url += ;
//				url += '&userid=' + wispData.publicState.wispUserId;
				var pages=[];
				for(var i = 0; i < numPages; i++) {
				var loadingPage = (
					<div className='pdfLoadPageDiv'>
						<FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ float: 'right', color: 'rgb(45,120,205)', marginTop: '3px', marginRight: '2px' }} />
					</div>
				);
					pages.push(
						<Page key={i+1} pageNumber={i+1} loading={loadingPage} />
					);
				}

				var file = {
					url: url,
					httpHeaders: {'Authorization': 'Bearer ' + wispData.token, 'user-id': wispData.publicState.wispUserId},
				};
				var cls = '';
				if (navOpen) {
					cls = 'float';
				}

				var loadingContent = (
					<div className='pdfLoadDocDiv'>{'Loading ' + curView.value.filename + '...'}</div>
			    );
							  
				// set document style class - wispBlog or wispClassic
				docView = (
					<div id='wispPageContId' className={'wispPageContainer wispBlog ' + cls} >
						<Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading={loadingContent}>
							{pages}
						</Document>
						{postLinks}
					</div>
				);
			}
		}

		if (navOpen) {
			nav = (
				<WispNav
					site={props.site}
					curPageId={curPageId}
					setViewFn={setView}
					curView={curView}
					selPanDocFN={selPanDoc}
					type="Book"
					showSubscribe={showSubscribeStr}
					subscribeFN={subscribeFN}
					busyMapId={busyMapId} />
			);
		}
	}

    return (
        <div>
			{nav}
			{guardian}
            {docView}
			{subscribeDialog}
        </div>
    );
}
  