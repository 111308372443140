import React, {useContext, useEffect} from 'react';
import {WispContext} from '../WispProvider';
import {doSignin} from '../util/user.js';
import { completeInvite } from '../util/site.js';
import { HTMLRenderer } from '../components/HTMLRenderer';
import { wispHistory } from '../components/WispHistory';
import { WispButton } from '../components/WispButton';
import { WispCheckBox } from '../components/WispCheckBox';
import { WispNotification } from '../components/WispNotification';
import { WispRadio } from '../components/WispRadio.js';
import { DocPopup } from './DocPopup.js';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getCookie } from '../util/base.js';
import {loadUserSites} from '../util/user.js';
import MaxBaggerLogo from '../images/max_logo_sml_blk.png';
import {WispInput} from '../components/WispInput'

// bcls - background color class
// d - inline-block, etc
// w - width of button
// js - onclick javascript
// m - margins
// mes - button message

export default function CreateAccountPopup(props) {

    var wispData = useContext(WispContext);

    const [creatingAccount, setCreatingAccount] = React.useState(false);
    const [agreeTerms, setAgreeTerms] = React.useState(false);
    const [showTerms, setShowTerms] = React.useState(false);
    const [createAccountState, setCreateAccountState] = React.useState({
        alertMessage: '',
    })
    const [didSetup, setDidSetup] = React.useState(false);
    const [showInreachInfo, setShowInreachInfo] = React.useState(false);

    function setAlertMessage(val) {
        if (createAccountState.alertMessage !== val) {
            setCreateAccountState((prevState) => {
                return {
                    ...prevState,
                    alertMessage: val,
                }
            });
        }
    }

    // var ts_loggingIn = false;
    // var mode = 'signin';

    window.ts_create = false;
    window.ts_didCreate = false;

    useEffect(() => {
        if (!didSetup) {
            $("#password").attr('type','password');
            $("#first_name").focus();
            /*
            $("#email").focus();
            var email = window.localStorage.getItem("email");   //getCookie('email');
            if (process.env.REACT_APP_SUBDOMAINS === 'true') {
                email = getCookie('email'); // can't use localstorage when doing subdomain prefixes
            }
            if (typeof email != 'undefined') {
                $("#email").val(email);
                $("#password").focus();
            } */
            setDidSetup(true);
        }
    }, []);

    function signIn() {
        wispData.setShowCreateAccount(false);
        wispData.setShowSignin(true);
    }

    function createAccount(){
        console.log('create Account');

        if (creatingAccount) {
            return;
        }

        if (!agreeTerms) {
            setAlertMessage('Please agree to the terms & conditions');
            return;

        }

        // Validate inputs
        if ($("#first_name").val() === '') {
            setAlertMessage('Please provide a first name');
            return;
        } else if ($("#last_name").val() === '') {
            setAlertMessage('Please provide a last name');
            return;
        } else if ($("#email").val() === '') {
            setAlertMessage('A valid email is required');
            return;
        } else if ($("#password").val().length < 5 || $("#password").val().length > 15) {
            setAlertMessage('Passwords must be 5-15 characters');
            return;
        }

        setCreatingAccount(true);
        var user = {
            email: $("#email").val(),
            first_name: $("#first_name").val(),
            last_name: $("#last_name").val(),
            password: $("#password").val(),
        }
        if (props.tracking) {
            user['location'] = $("#inreach_url").val();
        }
        if (wispData.referral && wispData.referral != '') {
            user['referrer'] = wispData.referral;
        }

        var headers = {
            'Content-Type': 'application/json',
			'app-id': process.env.REACT_APP_ID,
        };

        var url = process.env.REACT_APP_WISP_URL + "/user/";
        fetch(url, {method: 'POST', mode: 'cors', headers, body: JSON.stringify(user)})
        .then(function(response) {
            console.log('response.status = ' + response.status); // Will show you the status
            return response.json();
        })
        .then(data => {
            if (data.detail) {
                setAlertMessage(data.detail);
            } else {
                // created new user - now sign in
                doSignin(user.email, user.password, '', '', '', wispData, handleError, onSuccess);
            }
            setCreatingAccount(false);
        })
        .catch((error) => {
            setCreatingAccount(false);
            console.error('Error:', error);
        });
    }

	function onSuccess(userId, token, user) {
		wispData.setShowCreateAccount(false);
        console.log('createAccount: loadUserSites for userId = ' + userId);
        var tinviteSitemap;
        wispData.setInviteSitemap(prevValue => {tinviteSitemap = prevValue; return (prevValue)});
        if (tinviteSitemap) {
            completeInvite(wispData, tinviteSitemap, onSuccess2, userId, token);
        } else {
            onSuccess2(userId, token, user);
        }

    }
    
    function onSuccess2(userId, token, user) {
        if (props.successFN) {  // easyappdata/tengine.io seems to set this
            props.successFN(userId, user);
        } else {
            // how could there be any sites? just created the account - invite?
            loadUserSites(wispData, userId, userId, token, getSitesResult, true, userId);
        }
	}

    function getSitesResult(success, data, userId) {
        if (success && (data.detail !== 'invalid token')) {
            wispData.setUserSitesAuth(data);
            wispData.setUserSitesLoaded(true);

            if (props.onSignInFN) { // wispbook provides this, other apps should probably too to be like SignInForm
                props.onSignInFN(userId, data);
            } else {
                // by default after createaccount redirect to /userid
                // redirect prop can be used to override that to a specific path or 'false' means don't redirect at all (wispbook does that - just stay at root url)
                if (props.redirect) {
                    console.log('redirect = ' + props.redirect);
                    if (props.redirect !== 'false') {
                        wispHistory.push(props.redirect);
                    }
                } else {
                    console.log('userId = ' + userId);
                    wispHistory.push('/' + userId);
                }
            }
        }
    }

    function handleError(error) {
        setAlertMessage(error);
    }
    
    function closeCreateAccount() {
        wispData.setShowCreateAccount(false);
    }

    function emailInfo() {

    }

    function referralInfo() {

    }

	function handlePasswordKeyUp(e) {
		// console.log('handlePasswordKeyUp = ' + e.target.value);
		if (e.keyCode === 13) {
			createAccount();
		}
	}

    function showInReachInfo() {
        setShowInreachInfo(!showInreachInfo);
    }

    function toggleAgreeTerms() {
        setAgreeTerms(!agreeTerms);
    }

    function viewTerms() {
        setShowTerms(true);
    }

    function closePopup() {
        setShowTerms(false);
    }

    function selItem(item) {
        if (item.name.indexOf('existing') > 0) {
            signIn();
        }
    }

    // var w = $("body").width();
    // var leftPos = (w - 320)/2 + 'px';
    // var cls = 'wisp_but webt_ui_color_1 ' + props.bcls;
    // var w = props.w + "px";

    let existingAccount;
    if (props.showExisting) {
        existingAccount = (
            <p className='primaryColor' style={{fontSize:"14px",cursor:"pointer"}} onClick={signIn}>
                (Already have an account?)
            </p>
        );
    }
    /* let signInTitle = (
        <div>
            {props.title}
            {existingAccount}
        </div>
    ); */

    var messageDiv;
    var message = wispData.publicState.signinMessage;
    if (message != null) {
        messageDiv = (
            <div className='signin_mes'>{message}</div>
        )
    }

    var alertDiv;
    if (createAccountState.alertMessage !== '') {
        alertDiv = (
            <div style={{marginTop: '15px'}}>
                <WispNotification message={createAccountState.alertMessage} type='error' />
            </div>
        );
    }

    var noteDiv;
    if (props.note) {
        noteDiv = (
            <div style={{margin: '15px 0px'}}>
                <WispNotification message={props.note} type='warning' />
            </div>
        );
    }

    var inreachInfoDlg;
    if (showInreachInfo) {
        inreachInfoDlg = (
            <div style={{margin:'20px 10px 30px 0px'}} id='inreach_desc'>If you own a Garmin inReach, you can add location tracks to your Crazydoes adventures. The "share url" can be found by going to explore.garmin.com, signing in and choosing "Social". Your inReach URL can be setup or changed later in your Crazydoes account settings.</div>
        );
    }

    var inReach;
    if (props.tracking) {
        inReach = (
            <React.Fragment>
                <div className='trackingSection'>
                    GPS Tracking (optional)
                </div>
                <div style={{marginBottom: '20px'}}>
                    {inreachInfoDlg}
                    <div style={{display: 'flex'}}>
                        <div className="">inReach Share URL</div>
                        <div className='inReachInfoDiv' onClick={showInReachInfo}>
                            <FontAwesomeIcon className='infoCircle' icon={faInfoCircle} />
                        </div>
                    </div>
                    <input tabIndex='5' style={{width:'100%',boxSizing: 'border-box'}} className='wt_input' id='inreach_url'/>
                </div>
            </React.Fragment>
        );
    }

    var termsText = (
        <div>
            I have read and accept the {process.env.REACT_APP_NAME} <span className='primaryColor' style={{cursor: 'pointer'}} onClick={viewTerms}>&nbsp;Terms &amp; Conditions</span>
        </div>
    );
    var catItems = [
        {name: 'I have an existing account',
        },
        {name: 'I need a new account',
        }
    ];

    var logo;
    if (props.logo) {
        logo = (
            <div className="input_logo">
                <img src={MaxBaggerLogo} className="logoClassSmall" style={{margin: 'auto', display: 'block'}}/>
            </div>
        )
    }

    var content;
    if (showTerms) {
        var docId = props.termsDocId;
        if (process.env.REACT_APP_TEST === 'true') {
            docId = '5181501452845056'
        }
        content = (
            <DocPopup closeFN={closePopup} docId={docId} type='docx' user={null} view='mobile' />
        );
    } else {
        content = (
            <React.Fragment>
                <div className="close_pos_float_signin" onClick={closeCreateAccount}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
                {logo}
                <div style={{textAlign: 'center', fontSize: 24, fontWeight: 'bold'}}>Create Account</div>
                {messageDiv}
                {noteDiv}             
                <div style={{position:'relative'}}>
                    <div id='first_name_div' className="wt_inputdiv active">
                        <WispInput
                            id='first_name'
                            hint='First Name'
                            initValue=''
                            inputStyle={{paddingTop: '7px'}}
                            classes='active'
                            ti='1'
                        />
                    </div>
                    <div id='last_name_div' className="wt_inputdiv active">
                        <WispInput
                            id='last_name'
                            hint='Last Name'
                            initValue=''
                            inputStyle={{paddingTop: '7px'}}
                            classes='active'
                            ti='1'
                        />
                    </div>
                    <div id='email_div' className="wt_inputdiv active">
                        <WispInput
                            id='email'
                            hint='Email'
                            initValue=''
                            inputStyle={{paddingTop: '7px'}}
                            classes='active'
                            ti='1'
                        />
                    </div>
                    <div id='email_div_info' onClick={emailInfo} className='team_c_i'><i className='fa fa-info-circle'></i></div>
                    <div className='wt_floater' style={{display:'none',margin:'20px 10px 30px 10px'}} id='email_desc'>
                        <p>Your email address is used to log into {process.env.REACT_APP_NAME}.</p>
                    </div>
                    <div id='password_div' className="wt_inputdiv active">
                        <WispInput
                            id='password'
                            hint='Password'
                            initValue=''
                            inputStyle={{paddingTop: '7px'}}
                            classes='active'
                            ti='1'
                        />
                    </div>
                    <div id='referral_div' className="wt_inputdiv active" style={{display: 'none'}}>
                        <WispInput
                            id='referral'
                            hint='Referral'
                            initValue=''
                            inputStyle={{paddingTop: '7px'}}
                            classes='active'
                            ti='1'
                        />
                    </div>
                    <div id='referral_div_info' onClick={referralInfo} className='team_c_i'><i className='fa fa-info-circle'></i></div>
                    <div className='wt_floater' style={{display:'none',margin:'20px 10px 30px 10px'}} id='referral_desc'><p>If someone referred you to {process.env.REACT_APP_NAME} and gave you a referral code, then please enter it here.</p></div>
                    <div className='promo_res' id='referral_match'>
                        <div className='promo_res1 webt_ui_color_2'>Congratulations!</div>
                        <div className='promo_res2'>You're eligible for a free VIP subscription for 1 year ($99 value)</div>
                    </div>
                    {inReach}
                </div>
                <div className='acceptTermsDiv'>
                    <WispCheckBox id='acceptTermsCheck' text={termsText} title='' fontSize='12px' fontSizeCheck='16px' checked={agreeTerms} onclickFN={toggleAgreeTerms} arg1={1} arg2="" arg3="" />
                </div>
                <div style={{position:'relative'}}>
                    <WispButton
                        bcls='primaryButton'
                        d='block'
                        js={createAccount}
                        m='30px 0px 0px'
                        busy={creatingAccount}
                        mes='Create Account' />
                    <div id='forgotpwDiv' style={{margin:'10px 0px 0px 10px',width: 300}} onClick={signIn}>Already have an account?</div>
                    {alertDiv}
                </div>

            </React.Fragment>
        );
    }
    /*             <div className='docContainer'>
                <HTMLRenderer
                    url='https://api.crazydoes.com/document/5697028659085312?style=classic'
                />
            </div>
*/

    return (
        // <div className={cls}
        //     style={{
        //         display: props.d,
        //         padding:"8px",
        //         fontSize:"16px",
        //         width: w,
        //         textAlign: "center",
        //         cursor: "pointer",
        //         margin: props.m,
        //     }}
        //     onClick={props.js}>
        //     {props.mes}
        // </div>

        <React.Fragment>
            <div className='createAcctDiv'>
                {content}

            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}
