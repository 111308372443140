import React from 'react';
import {SupportPopup} from '../popups/SupportPopup';
import {wispHistory} from './WispHistory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppStore } from '@fortawesome/free-brands-svg-icons';

export const Footer = (props) => {

    const [showSupportForm, setShowSupportForm] = React.useState(false);

    function termsOfService() {
        wispHistory.push('/terms');
    }

    function privacyPolicy() {        
        wispHistory.push('/privacy');
    }

    function referralsClick() {
        wispHistory.push('/referrals');
    }

    function homeSupport() {
        setShowSupportForm(true);
        console.log('footer scroll');
        window.scrollTo(0,0);
    }

    function closeSupp() {
        setShowSupportForm(false);
    }
    
    var supportForm;
    if (showSupportForm) {
        supportForm = (
            <SupportPopup closeSupp={closeSupp}/>
        );
    }

    var mobileApp;
    if (props.mobileApp) {
        mobileApp = (
            <div className='footer_item' onClick={() => {window.location = 'https://apps.apple.com/us/app/crazydoes/id1570555460'}}>
                <div style={{display: 'flex'}}>
                    <div><FontAwesomeIcon className='fa-2x' icon={faAppStore} style={{marginTop: '5px'}} /></div>
                    <div style={{margin: '7px 0px 0px 20px'}}>Crazydoes iOS mobile app</div>
                </div>
            </div>
        );
    }

    var referrals;
    if (props.referrals) {
        referrals = (
            <div className='footer_item' onClick={referralsClick}>Referrals Program</div>
        )
    }

    return (
        <div className='footer'>
            <div className='footerCont'>
                <div className='wisp_in footer_column'>
                    <div className='footer_header'>Contact Us</div>
                    <div className='footer_item' onClick={homeSupport}>Support</div>                            
                </div>
                <div className='wisp_in footer_column footer_column2'>
                    <div className='footer_header'>Corporate</div>
                    <div className='footer_item' onClick={termsOfService}>Terms of Service</div>
                    <div className='footer_item' onClick={privacyPolicy}>Privacy Policy</div>
                    {referrals}
                </div>
                <div className='wisp_in footer_column3'>
                    {mobileApp}
                    <div className='footer_item'>
                        {process.env.REACT_APP_NAME} and www.{process.env.REACT_APP_URL_POSTFIX} &copy; 2024 {process.env.REACT_APP_COPYRIGHT_NAME}
                    </div>
                </div>
                {supportForm}
            </div>
        </div>
    );
    
}

