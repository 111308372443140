import React, { useEffect } from 'react';
import {WispButton} from './components/WispButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

export default function PortfolioCreate(props) {
	// var wispData = useContext(WispContext);
    const [createOption, setCreateOption] = React.useState("blank");

	useEffect(() => {
        if (!props.wispData || !props.wispData.mobileView) {
            var w = $('body').width();
            var w2 = $("#decisionDiv").width();
            var l = w/2 - w2/2;
        }
        $("#decisionok").focus();
	}, []);

	function closeCreateOptions() {
		props.closeFN();
    }

    function changeOption(option) {
        setCreateOption(option);
    }

    return (
        <div>
            <div class= "createDivOverlay"></div>
            <div class="createDiv">
                <div class="createHeader">
                    Create Options
                    <div onClick={closeCreateOptions}>
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes} />
                    </div>
                </div>
                <div class="createOptionsContainer">
                    <div 
                        className={`iconContainer ${createOption === "blank" ? "selected" : ""}`} 
                        onClick={() => changeOption("blank")}
                    >
                        <div class="optionIcon"></div>
                        <div class="iconText">Blank</div>
                    </div>
                    <div 
                        className={`iconContainer ${createOption === "spreadsheet" ? "selected" : ""}`} 
                        onClick={() => changeOption("spreadsheet")}
                    >
                        <div class="optionIcon"></div>
                        <div class="iconText">Upload Spreadsheet</div>
                    </div>
                    <div 
                        className={`iconContainer ${createOption === "onedrive" ? "selected" : ""}`} 
                        onClick={() => changeOption("onedrive")}
                    >
                        <div class="optionIcon"></div>
                        <div class="iconText">OneDrive Spreadsheet</div>
                    </div>
                </div>
                <div class="createPortfolioButton">
                    {createOption != "" ? ( // Render button inactive if no option is selected
                        <WispButton
                            bcls='primaryButton'
                            d='inline-block'
                            width="200px"
                            js={() => props.fn1(props.p1)}
                            m='0px'
                            mes={props.c1} />
                    ) : (
                        <WispButton
                            bcls= 'inactiveButton'
                            d='inline-block'
                            width="200px"
                            m='0px'
                            mes={props.c1} />
                    )}
                </div>
            </div>
        </div>
    );

}
