import React, { useEffect } from 'react';
import { WispButton } from './components/WispButton';
import { WispInput } from './components/WispInput';
import { fetchGet, wisp_urls, getWispUrl} from './util/fetch.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'; // Import the trash icon


export default function PortfolioTable(props) {
	// var wispData = useContext(WispContext);
    const [tickers, setTickers] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [clearInputFlag, setClearInputFlag] = React.useState(false);

	useEffect(() => {
        getTickers()
	}, []);

    function addRow() {
        //Check to see if the ticker is already present in the table
        setTickers(prevTickers => [
            ...prevTickers,
            { name: inputValue, price: Math.floor(Math.random() * 10) + 1, estimatedGain: 'N/A', fairValuePrice: 'N/A' }
        ]);
        setInputValue('');
        setClearInputFlag(true);
    }

    function alreadyPresent(ticker) { //Checks if a ticker is already in the table
        for (var i = 0; i < tickers.length; i++) {
            if (ticker == tickers[i].name) {
                return true
            }
        }
        return false
    }

    function sortAlphabetically(index) {
        if (index == 0) {
            const sortedTickers = [...tickers].sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0; // Names are equal
            });
            setTickers(sortedTickers);
        } else if (index == 1) {
            const sortedTickers = [...tickers].sort((a, b) => {
                if (a.price < b.price) return 1;
                if (a.price > b.price) return -1;
                return 0; // Names are equal
            });
            setTickers(sortedTickers);
        
        }
    }

    function createRows(success, stockData) {
        for (var i = 0; i < stockData.length; i++) {
            setTickers(prevTickers => [
                ...prevTickers,
                { name: stockData[i], price: 'N/A', estimatedGain: 'N/A', fairValuePrice: 'N/A' }
            ]);
        }
    }

    function deleteStock(ticker) {
        console.log(ticker)
        var url = wisp_urls.stock_delete;
        var token = props.token;
        var headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'site-id': props.siteId,
            'user-id': props.userId,
        }
        const stockData = {
            'ticker': ticker
        };
        fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(stockData) })	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                deleteRow(ticker)
                console.log('success = ' + data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function deleteRow(ticker) {
        setTickers(prevTickers => 
            prevTickers.filter(stock_data => stock_data.name !== ticker)
        );
        console.log(tickers)
    }

    function getTickers() {
        var url = getWispUrl(wisp_urls.stock_get, props.siteId);
        var token = props.token;
        var headers = {
            'Authorization': 'Bearer ' + token,
            'user-id': props.userId,
        }
        fetchGet(url, createRows, {header: headers})
    }

    function saveTicker() {
        if (!alreadyPresent(inputValue) && inputValue) {
            var url = wisp_urls.stock_create;
            var token = props.token;
            var headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'site-id': props.siteId,
                'user-id': props.userId,
            }
            const stockData = {
                'ticker': inputValue
            };
            fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(stockData) })	//  mode: 'cors', headers: headers,
                .then(function (response) {
                    console.log('response.status = ' + response.status); // Will show you the status
                    return response.json();
                })
                .then(data => {
                    addRow()
                    console.log('success = ' + data);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    const handleInputChange = (value) => {
        setInputValue(value);
    };
    
    var headers;
    headers = ["Ticker", "Price", "Estimated Gain", "Fair Value Price", "Edit"]

    var tableHeader; // Can be utilized later to get data from the database
    tableHeader = (
        <thead>
            <tr>
            {headers.map((header, index) => ( // Creates headers for each item we want
                <th key={index} onClick={() => sortAlphabetically(index)}>{header}</th>
            ))}
            </tr>
        </thead>
    )

    var tableBody;
    tableBody = (
        <tbody>
            {tickers.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    <td>{row.name}</td>
                    <td>{row.price}</td>
                    <td>{row.estimatedGain}</td>
                    <td>{row.fairValuePrice}</td>
                    <td>
                        <FontAwesomeIcon icon={faTrash} onClick={() => deleteStock(row.name)} />
                    </td>
                </tr>
                ))}
        </tbody>
    )

    return (
        <div className="tableContainer">
            <div className="tableTitle">
                <div className="tableTitleText">Portfolio Name</div>
                <div id="ticker_input" className="tableTitleText" style={{float: "right"}}>
                    <WispInput
                        id='ticker'
                        hint='Add Ticker'
                        initValue=''
                        inputStyle={{paddingTop: '7px'}}
                        classes='active'
                        ti='1'
                        onChange={handleInputChange}
                        upperCase={true}
                        clearInput={clearInputFlag}
                        setClearInputFlag={setClearInputFlag}
                    />
                </div>
                <div className="tableTitleText">
                    <WispButton
                        bcls='primaryButton'
                        width="200px"
                        js={() => saveTicker()}
                        m='0px'
                        mes={"Add Ticker"} />
                </div>
            </div>
            <table className="styled-table">
                {tableHeader}
                {tableBody}
            </table>
        </div>
    );

}
