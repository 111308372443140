import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {stopDefault} from '../util/base.js';
import $ from 'jquery';

// id        = base id used to create html ids (i.e. id + "Div", id + "but"), and li onclick (if fn isn't specified)
// val       = default value of drop down menu (should be an entry in options[])
// nOptions  = # of list items
// options   = array of list items
// fn        = li onclick will call this after default handling
// arg       = 1st param of fn
// arg2      = 2nd param of fn
// arg3      = 3rd argument fn
// display   = display style of entire div
// numH  = number of li's initially displayed when menu is opened.
// el    = set dropdown to div or td
// ph   = height padding on anchors - which controls select height
// w    = minimum width of ul
// w2   = minimum width of menu
// va   = valign alignment of menu
// pt   = padding top of menu
// bc    = background class
// fs    = font size
// as    = anchor style - disabled for now

export const WispSelect = (props) => {

    useEffect(() => {
        window.addEventListener('click', selectClicker, false);
        return () => {
            // Anything in here is fired on component unmount.
            window.removeEventListener('click', selectClicker, false);
        }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    function selectClicker(event) {
        //Hide the menus if visible
        // console.log('wispselect click');
        var $target = $(event.target);
        var id = props.id + "Div";
        var idPicker = props.id + 'Picker';
        if(!$target.closest('#' + id).length && 
        $('#' + idPicker).is(":visible")) {
            $('#' + idPicker).hide();
        }
    }

    function chooseThemeSels(){
        var id = props.id;
        if(!$("#" + id + "Picker").is(":visible")){
            $("#" + id + "Picker").show();
            $("#" + id + "but").removeClass('btn-primary');
        }
        else
            $("#" + id + "Picker").hide();
    }    

    function selectItem(e){
        // console.log('selectItem: e = ' + e.target.innerText);
        var val = e.target.innerText;
        if(val !== $("#" + props.id).html()){
            $("#" + props.id).html(val);
            if (props.fn) {
                props.fn(val,props.arg1,props.arg2,props.arg3);
            }
        }
        $("#" + props.id + "Picker").hide();
    }
    
    var w = props.w;
    if(typeof w === 'undefined') {
        w = '200';
    }
    var aWidth = parseInt(w) - 30;
    var w2 = props.w2;
    if(typeof w2 === 'undefined') {
        w2 = '100';
    }
    var va = props.va;
    if(typeof va === 'undefined' || va === '') {
        va = 'middle';
    }
    var pt = props.pt;
    if(typeof pt === 'undefined' || pt === '') {
        pt = '0';
    }
    var bc = '';
    if (props.bc) {
        bc = props.bc;
    }

    var as = props.as;
    if(typeof as === 'undefined') {
        as = '';
    }
    var display = props.display;
    if(typeof display === 'undefined') {
        display = 'block';
    }
    var ph = props.ph;
    if(typeof ph === 'undefined') {
        ph = '10';
    }

    var id = props.id;
    var numH = props.numH;
    var nOptions = props.nOptions;
    if(numH === '' || numH > nOptions) //default to nOptions if nothing specified, or nOptions less than numH
    numH = nOptions;
    var h = 0;
    h = (numH*30-1);   // similar calc in main.js visibilityDropdown - lis are set to 19 height + 10 px padding + all but first have 1 px border
    if(h > 500) {
        h = 500;
    }

    var items = [];
    for(var i = 0;i < nOptions;i++){
        // var m = '7';
        var border = '1px solid #EEE';
        if(i === 0){
            // m = '4';
            border = '';
        }
        items.push(
            <li onMouseDown={stopDefault}
                key={i + '_key'}
                style={{height:'19px', borderTop: border}}
                onClick={e => selectItem(e)}>
                {props.options[i]}
            </li>
        );
    }
    var spinner;
    if (props.busy) {
        spinner = (
            <FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ float: 'right', color: '#white', marginTop: '1px', marginRight: '0px' }} />
        )
    }

    return (
        <div valign={va} style={{display: display,cursor:'pointer',position:'relative', paddingTop: pt+'px'}}>
            <div id={id + 'Div'} className='btn-group open'>
                <a id={id +'sel'} 
                    className={'primarySelect wispSelectA ' + bc} 
                    style={{
                        minWidth:aWidth + "px",
						textDecoration: 'none'
					}}
                    onMouseDown={stopDefault}
                    onClick={chooseThemeSels}>
                    <span id={id}>
                        {props.val}
                    </span>
                    {spinner}
                </a>
                <a id={id + 'but'} 
                    className={'btn primarySelect ' + bc} 
					onClick={chooseThemeSels}
                    style={{
                        textDecoration: 'none',
                        width:'10px',
                        }}>
                    <FontAwesomeIcon icon={faAngleDown} style={{fontSize:"12px"}} />
                </a>
                <ul id={id + 'Picker'}
                    onMouseDown={stopDefault} 
                    className='dropdown-menu' 
                    style={{minWidth:w + 'px',fontSize:'12px',padding:'5px 0px',height: h + 'px',overflowY:'auto'}}>
                    {items}
                </ul>
            </div>
        </div>
    );
}

